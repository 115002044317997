.about-us{
  height: 100vh;
  padding: 5%;
}

.about-us .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-us .line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.about-us .content-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1%;
  height: 75%;
}

.about-us .grid-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.about-us .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us .item-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 4rem;
  padding-inline: 1rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  color: #fff;
  height: 0;
  width: 80%;
  overflow: hidden;
  transition: height 0.5s;
  }

  .about-us .item-details h2 {
    color: ghostwhite;
    font-family: 'Gothic A1', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    position: relative; /* needed to position ::before */
    z-index: 1; /* needed to position ::before above the h2 text */
  }

  .about-us .item-details h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%); /* adjust opacity stops as desired */
    filter: blur(10px); /* adjust blur value as desired */
  }


.about-us .item-details p {
  padding-top: 1rem;
}

.about-us .grid-item:hover .item-details {
  height: 75%;
padding-top: 10rem;
opacity: 1;
}

.cta {
  border: none;
  background: none;
}

.cta span {
  padding-bottom: 7px;
  font-size: 14px;
  padding-right: 15px;
  font-family: 'Gothic A1', sans-serif;
  color: ghostwhite;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
  color: #e63946;
}

.cta:hover svg {
  transform: translateX(0);
  color: #e63946;
}


.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: #e63946;;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e63946;;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

@media screen and (max-width: 768px) {
  .about-us{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }
  .about-us .content-container {
    grid-template-columns: 1fr;
    height: 5rem;
  }

  .about-us .grid-item {
    height: 5rem;
  }

  .about-us .item-details {
    height: 100%;
    padding-bottom: 0rem;
    padding-top: 2rem;

  }

  .about-us .item-details p {
    display: none;
  }

  .about-us .grid-item:hover .item-details {
    padding-top: 2rem;
  }
}
