html {
  height: 100%;
}
body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
}

.container {
  width: 400vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
/*
.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.lastContainer {
  display: flex;
  height: 85vh;
}

.panel {
  width: 100vw;
}

/* @media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .panel {
    width: 100%;
    height: 100vh;
  }
} */
