.machine-production{
  height: 100vh;
  padding: 5%;
  background-color: ghostwhite;
}

.machine-production .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.machine-production .content-container {
  height: 70%;
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  justify-items: stretch;
  align-items: stretch;
  padding: 1rem;
}

.machine-production .content-container > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: none;
}

.machine-production .content-container > div:hover {
  transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}





.machine-production .content-container h2 {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  line-height: 1rem;
}

.machine-production .content-container p{
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  border-left: 3px solid #e63946;
  padding: 0rem 1rem 0rem 1rem;
}

.machine-production .content-container .mobile-container img {
  width: 100%;
  height: 200px;
  background-color: ghostwhite;
  object-fit: cover;
  object-position: center;
}

.machine-production .content-container .mobile-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
}


.read-more-btn {
  background-color: #e63946;
  color: #FFFFFF;
  border: none;
  padding: 10px 15px;
  text-align: center; text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.read-more-btn:hover {
  background-color: white;
  color: #e63946;
  border: 0 solid #e63946;
}

.card {
  background-color: ghostwhite
}


/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .machine-production{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }
  .machine-production .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: auto;
  }

  .machine-production .content-container div{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .machine-production h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  /* .machine-production .content-container div .mobile-container {
    display: flex;
    flex-direction: row;
    align-items: space-evenly;
    justify-content: space-evenly;
    gap: 1rem;
  } */
  .machine-production .content-container div .mobile-container p {
    font-size: xx-small;
    width: 85%
  }

  .machine-production .content-container .mobile-container img{
    max-height: 75px;
  }
  .line{
    display: none;
  }
}
