.banner-container {
  position: relative;
  display: inline-block;  /* Adjust as needed */
  height: 20vh;
}

.banner-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #121212, rgba(0, 0, 0, 0));
}

.banner {
  display: block;  /* Removes bottom margin/padding */
  width: 100%;  /* Adjust as needed */
  height: 20vh;
  object-fit: cover;
  border-radius: 8px;
}

.banner-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  margin: 0;
  z-index: 2; /* Make sure the title is visible on top of the image */
}

.banner-container .section-text {
  font-size: 1.5rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container .content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
}

.banner-container .content-item {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  color: ghostwhite; /* Secondary color */
}

.m1 {
  background-image: url('../../assets/rm1.jpeg');
  background-color: ghostwhite;
  height: 10rem;
}

.m2 {
  background-image: url('../../assets/rolling-mill-transparent.png');
  background-color: ghostwhite;
  height: 10rem;
}

.m3 {
  background-image: url('../../assets/rm3.jpeg');
  background-color: ghostwhite;
  height: 10rem;
}

.m4 {
  background-image: url('../../assets/rm4.jpeg');
  background-color: ghostwhite;
  height: 10rem;
}
.m5 {
  background-image: url('../../assets/rm5.jpeg');
  background-color: ghostwhite;
  height: 10rem;
}
.m6 {
  background-image: url('../../assets/customersupport.jpeg');
  background-color: ghostwhite;
  height: 10rem;
}


/* Responsive design for mobile devices */
@media (max-width: 768px) {

  .banner-container .banner-title {
    font-size: 1.5rem;
    width: 75%;
  }

  .banner-container .section-text {
    font-size: 1.2rem;
  }

  .banner-container p {
    font-size: 0.875rem;
  }

  .banner-container .content-container {
    grid-template-columns: 1fr;
  }
}
