body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a:hover, li:hover {
  cursor:default
}

html {
  height: 100%;
}
body {
  overflow-y: visible;
  position: relative;
  overflow-x: hidden;
}
html,
body {
  margin: 0;
}

.container {
  width: 400vw;
  height: 100vh;
  display: flex;
  flex-wrap: wrap;
}
/*
.firstContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
} */

.lastContainer {
  display: flex;
  height: 85vh;
}

.panel {
  width: 100vw;
}

/* @media (max-width: 768px) {
  .container {
    flex-direction: column;
  }
  .panel {
    width: 100%;
    height: 100vh;
  }
} */

.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
  background: #121212;
  height: 1.5rem;
}

.company-name {
  font-size: 1rem;
  margin: 0;
  color: ghostwhite;
  font-family: 'Gothic A1', sans-serif;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 3.5rem;
  margin-right: 0.5rem;
}

.menu-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: rgb(255, 51, 0);
  z-index: 2000;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.fullscreen-menu li {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-family: 'Gothic A1', sans-serif;
}

.fullscreen-menu li:last-child {
  border-bottom: none;
}

.fullscreen-menu a {
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: 'Gothic A1', sans-serif;
}

.fullscreen-menu a:hover {
  color: ghostwhite;
}

/* desktop version */
.desktop-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.desktop-nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.desktop-nav li {
  margin-left: 1rem;
  font-family: 'Gothic A1', sans-serif;

}

.desktop-nav a {
  color: ghostwhite;
  text-decoration: none;
  font-family: 'Gothic A1', sans-serif;
  transition: color 0.3s ease-in-out, font-size 0.2s ease-in-out;
}

.desktop-nav a:hover {
  color: rgb(255, 51, 0);
  transition: color 0.3s ease-in-out, font-size 0.2s ease-in-out;
}



/* ... dropdown content ... */

.desktop-nav .dropdown {
  position: relative;
  display: inline-block;
}

.desktop-nav .dropdown-content {
  display: none;
  position: absolute;
  background: #121212;
  min-width: 160px;
  z-index: 1001;
}

.desktop-nav .dropdown-content li {
  padding: 12px;
  display: block;
  font-family: 'Gothic A1', sans-serif;
}

.desktop-nav .dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}
.desktop-nav .dropdown-content li:hover a:hover{
  color: white;
}

.desktop-nav .dropdown:hover .dropdown-content {
  display: block;
}

/* ... mobile version ... */

.services-button {
  background-color: transparent;
  border: none;
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  font-family: 'Gothic A1', sans-serif;
}

.services-button:hover {
  color: rgb(131, 77, 64);
}

.mobile-dropdown {
  position: relative;
}

.mobile-dropdown-content {
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.mobile-dropdown.show .mobile-dropdown-content {
  display: block;
}

.mobile-dropdown-content li {
  padding: 1rem 2rem;
  display: block;
  font-family: 'Gothic A1', sans-serif;
  font-size: small;
  border-top: 0.5px solid #121212
}

.mobile-dropdown-content li a {
  font-size: x-small;

}

.mobile-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}

.mobile-menu-container {
  overflow-y: auto;
  padding-right: 1rem;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);

}

/* ... language dropdown ... */

.language-dropdown {
  position: absolute;
  top: 0;
  right:10;
}

.language-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%; /* Align the dropdown menu under the nav item */
  background: #121212;
  min-width: 160px;
  z-index: 1001;
}

.language-dropdown-content li {
  padding: 18px;
  display: none; /* Hide all list items by default */
  font-family: 'Gothic A1', sans-serif;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: smaller;
}

.language-dropdown-content li.active-language {
  display: block; /* Show only the active language */
}

.language-dropdown:hover .language-dropdown-content {
  display: block; /* Show the dropdown when hovered */
}

.language-dropdown:hover .language-dropdown-content li {
  display: block; /* Show all languages when hovered */
}
.language-dropdown:hover .language-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}
.language-dropdown:hover .language-dropdown-content li:hover .globe-icon {
  color: #121212;
}

.language-dropdown-button {
  background-color: transparent;
  border: none;
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  font-family: 'Gothic A1', sans-serif;
}

.language-dropdown-button:hover {
  color: rgba(255, 51, 0, 0.7);
}

.mobile-language-dropdown {
  position: relative;
}

.mobile-language-dropdown-content {
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.mobile-language-dropdown.show .mobile-language-dropdown-content {
  display: block;
}

.mobile-language-dropdown-content li {
  padding: 1rem 2rem;
  display: block;
  font-family: 'Gothic A1', sans-serif;
  font-size: small;
  border-top: 0.5px solid #121212;
  color: white
}

.mobile-language-dropdown-content li a {
  font-size: x-small;
  color: white;
}

.mobile-language-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
  color: white;
}
.mobile-language-dropdown-content li:hover .globe-icon{
  color: #121212;
}



.globe-icon {
  margin-right: 5px;
  vertical-align: top;
  color: rgb(255, 51, 0);
}


/* Apply styles for desktop devices */
@media (min-width: 768px) {
  .menu-toggle {
    display: none;
  }

  .desktop-nav {
    display: flex;
  }

  .language-dropdown {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Apply styles for mobile devices */
@media (max-width: 767px) {
  .desktop-nav {
    display: none;
  }

  .language-dropdown {
    position: static;
    margin-left: auto;

  }
}

.home {
  text-align: left;
  overflow: hidden;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

/* New CSS rule for the video element */
.home video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  loop: true;
}

.home video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at center, rgba(221, 55, 0, 0.238) 10%, rgba(221, 55, 0, 0.471) 20%, rgba(221, 0, 0, 0.368) 40%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 70%);
  mix-blend-mode: overlay;
  z-index: -1;
  opacity: 0.6;
}


.home h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  opacity: 0;
  -webkit-transform: translateY(50px);
          transform: translateY(50px);
  -webkit-animation: fadeInUp 1s ease-out forwards;
          animation: fadeInUp 1s ease-out forwards;
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}

/* Add this rule to position the content correctly */

.home .cta-container {
  height: 100%;
  padding-inline: 10rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 70%);

}

.home p {
  color: ghostwhite;
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}


.home button {
  width: 15rem;
  --red: #FF4136;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--red);
  background: linear-gradient(to right, rgba(255, 65, 54, 0.1) 1%, transparent 40%, transparent 60%, rgba(255, 65, 54, 0.1) 100%);
  color: var(--red);
  box-shadow: inset 0 0 10px rgba(255, 65, 54, 0.4), 0 0 9px 3px rgba(255, 65, 54, 0.1);
}

.home button:hover {
  color: #ffffff;
  box-shadow: inset 0 0 30px rgba(255, 65, 54, 0.6), 0 0 9px 10px rgba(255, 65, 54, 0.4);
}

.home button:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: -webkit-transform .4s ease-in-out;
  transition: transform .4s ease-in-out;
  transition: transform .4s ease-in-out, -webkit-transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(255, 65, 54, 0.1) 40%,rgba(255, 65, 54, 0.1) 60% , transparent 100%);
}

.home button:hover:before {
  -webkit-transform: translateX(15em);
          transform: translateX(15em);
}



@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}



@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .home .cta-container {
    padding-inline: 2rem;
  }

  .home h2 {
    font-size: smaller;
  }

  .home p {
    font-size: 1rem;
  }

  .home button {
    width: 12rem;
    font-size: 13px;
    padding: 0.5em 2.2em;
  }
}

.about-us{
  height: 100vh;
  padding: 5%;
}

.about-us .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.about-us .line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.about-us .content-container{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 1%;
  gap: 1%;
  height: 75%;
}

.about-us .grid-item {
  position: relative;
  overflow: hidden;
  height: 100%;
}

.about-us .grid-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.about-us .item-details {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: 4rem;
  padding-inline: 1rem;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0));
  color: #fff;
  height: 0;
  width: 80%;
  overflow: hidden;
  transition: height 0.5s;
  }

  .about-us .item-details h2 {
    color: ghostwhite;
    font-family: 'Gothic A1', sans-serif;
    font-weight: bold;
    font-size: 1rem;
    position: relative; /* needed to position ::before */
    z-index: 1; /* needed to position ::before above the h2 text */
  }

  .about-us .item-details h2::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 50%, rgba(0,0,0,1) 100%); /* adjust opacity stops as desired */
    -webkit-filter: blur(10px);
            filter: blur(10px); /* adjust blur value as desired */
  }


.about-us .item-details p {
  padding-top: 1rem;
}

.about-us .grid-item:hover .item-details {
  height: 75%;
padding-top: 10rem;
opacity: 1;
}

.cta {
  border: none;
  background: none;
}

.cta span {
  padding-bottom: 7px;
  font-size: 14px;
  padding-right: 15px;
  font-family: 'Gothic A1', sans-serif;
  color: ghostwhite;
}

.cta svg {
  -webkit-transform: translateX(-8px);
          transform: translateX(-8px);
  transition: all 0.3s ease;
  color: #e63946;
}

.cta:hover svg {
  -webkit-transform: translateX(0);
          transform: translateX(0);
  color: #e63946;
}


.cta:active svg {
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: #e63946;;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #e63946;;
  -webkit-transform-origin: bottom right;;
          transform-origin: bottom right;
  transition: -webkit-transform 0.25s ease-out;
  transition: transform 0.25s ease-out;
  transition: transform 0.25s ease-out, -webkit-transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  -webkit-transform-origin: bottom left;
          transform-origin: bottom left;
}

@media screen and (max-width: 768px) {
  .about-us{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }
  .about-us .content-container {
    grid-template-columns: 1fr;
    height: 5rem;
  }

  .about-us .grid-item {
    height: 5rem;
  }

  .about-us .item-details {
    height: 100%;
    padding-bottom: 0rem;
    padding-top: 2rem;

  }

  .about-us .item-details p {
    display: none;
  }

  .about-us .grid-item:hover .item-details {
    padding-top: 2rem;
  }
}

.raw-material-production {
  height: 100vh;
  padding: 5%;
  background-color: white;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.raw-material-production .content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  background-color: ghostwhite;
  height: 60%;
}

.raw-material-production .content-container .text-container {
  width: 60%;
}

.raw-material-production .content-container .text-container p {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.01rem;
}

.raw-material-production .content-container .request-service {
  display: inline-block;
  background-color: #e63946;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;
  border: 0px solid;
}

.raw-material-production .content-container .image-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.raw-material-production .content-container .image-container img {
  width: 100%;
  height: auto;
  padding: 1rem;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .raw-material-production{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }

  .raw-material-production h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .raw-material-production .content-container .text-container p {
    color: black;
    font-family: 'Gothic A1', sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
  }

  .raw-material-production .content-container .text-container {
    width: 100%;
  }

  .raw-material-production .content-container .image-container {
    display: none;
  }
}

.machine-production{
  height: 100vh;
  padding: 5%;
  background-color: ghostwhite;
}

.machine-production .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.machine-production .content-container {
  height: 70%;
  background: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 1rem;
  gap: 1rem;
  justify-items: stretch;
  align-items: stretch;
  padding: 1rem;
}

.machine-production .content-container > div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  transition: box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  transition: transform 0.3s ease, box-shadow 0.3s ease, -webkit-transform 0.3s ease;
  box-shadow: none;
}

.machine-production .content-container > div:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}





.machine-production .content-container h2 {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
  font-size: 1rem;
  text-align: left;
  line-height: 1rem;
}

.machine-production .content-container p{
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 0.8rem;
  letter-spacing: 0.01rem;
  border-left: 3px solid #e63946;
  padding: 0rem 1rem 0rem 1rem;
}

.machine-production .content-container .mobile-container img {
  width: 100%;
  height: 200px;
  background-color: ghostwhite;
  object-fit: cover;
  object-position: center;
}

.machine-production .content-container .mobile-container{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  align-items: flex-start;
}


.read-more-btn {
  background-color: #e63946;
  color: #FFFFFF;
  border: none;
  padding: 10px 15px;
  text-align: center; text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  transition-duration: 0.4s;
}

.read-more-btn:hover {
  background-color: white;
  color: #e63946;
  border: 0 solid #e63946;
}

.card {
  background-color: ghostwhite
}


/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .machine-production{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }
  .machine-production .content-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    height: auto;
  }

  .machine-production .content-container div{
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
  }

  .machine-production h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  /* .machine-production .content-container div .mobile-container {
    display: flex;
    flex-direction: row;
    align-items: space-evenly;
    justify-content: space-evenly;
    gap: 1rem;
  } */
  .machine-production .content-container div .mobile-container p {
    font-size: xx-small;
    width: 85%
  }

  .machine-production .content-container .mobile-container img{
    max-height: 75px;
  }
  .line{
    display: none;
  }
}

.banner-container {
  position: relative;
  display: inline-block;  /* Adjust as needed */
  height: 20vh;
}

.banner-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #121212, rgba(0, 0, 0, 0));
}

.banner {
  display: block;  /* Removes bottom margin/padding */
  width: 100%;  /* Adjust as needed */
  height: 20vh;
  object-fit: cover;
  border-radius: 8px;
}

.banner-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  margin: 0;
  z-index: 2; /* Make sure the title is visible on top of the image */
}

.banner-container .section-text {
  font-size: 1.5rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container .content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
}

.banner-container .content-item {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  color: ghostwhite; /* Secondary color */
}

.cb1 {
  background-image: url(/static/media/cb4.72bb6a67.png);
  background-color: ghostwhite;
  height: 10rem;
}

.cb2 {
  background-image: url(/static/media/cb2.0f370a56.png);
  background-color: ghostwhite;
  height: 10rem;
}

.cb3 {
  background-image: url(/static/media/cbBanner.ea2f2d88.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.cb4 {
  background-image: url(/static/media/cbbanner2.663396a4.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}


/* Responsive design for mobile devices */
@media (max-width: 768px) {

  .banner-container .banner-title {
    font-size: 1.5rem;
    width: 75%;
  }

  .banner-container .section-text {
    font-size: 1.2rem;
  }

  .banner-container p {
    font-size: 0.875rem;
  }

  .banner-container .content-container {
    grid-template-columns: 1fr;
  }
}

.banner-container {
  position: relative;
  display: inline-block;  /* Adjust as needed */
  height: 20vh;
}

.banner-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #121212, rgba(0, 0, 0, 0));
}

.banner {
  display: block;  /* Removes bottom margin/padding */
  width: 100%;  /* Adjust as needed */
  height: 20vh;
  object-fit: cover;
  border-radius: 8px;
}

.banner-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  margin: 0;
  z-index: 2; /* Make sure the title is visible on top of the image */
}

.banner-container .section-text {
  font-size: 1.5rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container .content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
}

.banner-container .content-item {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  color: ghostwhite; /* Secondary color */
}

.s1 {
  background-image: url(/static/media/shredder.6a53cf61.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.s2 {
  background-image: url(/static/media/shredder2.ced72129.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.s3 {
  background-image: url(/static/media/shredder3.2c149e3b.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.s4 {
  background-image: url(/static/media/shredder4.31f4a3ef.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.s5 {
  background-image: url(/static/media/shredder5.08591d00.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.s6 {
  background-image: url(/static/media/customersupport.5589b8fc.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}


/* Responsive design for mobile devices */
@media (max-width: 768px) {

  .banner-container .banner-title {
    font-size: 1.5rem;
    width: 75%;
  }

  .banner-container .section-text {
    font-size: 1.2rem;
  }

  .banner-container p {
    font-size: 0.875rem;
  }

  .banner-container .content-container {
    grid-template-columns: 1fr;
  }
}

.banner-container {
  position: relative;
  display: inline-block;  /* Adjust as needed */
  height: 20vh;
}

.banner-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #121212, rgba(0, 0, 0, 0));
}

.banner {
  display: block;  /* Removes bottom margin/padding */
  width: 100%;  /* Adjust as needed */
  height: 20vh;
  object-fit: cover;
  border-radius: 8px;
}

.banner-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  margin: 0;
  z-index: 2; /* Make sure the title is visible on top of the image */
}

.banner-container .section-text {
  font-size: 1.5rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container .content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
}

.banner-container .content-item {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  color: ghostwhite; /* Secondary color */
}

.m1 {
  background-image: url(/static/media/rm1.57abdb64.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.m2 {
  background-image: url(/static/media/rolling-mill-transparent.5474658a.png);
  background-color: ghostwhite;
  height: 10rem;
}

.m3 {
  background-image: url(/static/media/rm3.f2726dff.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.m4 {
  background-image: url(/static/media/rm4.c8260974.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}
.m5 {
  background-image: url(/static/media/rm5.b40344cd.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}
.m6 {
  background-image: url(/static/media/customersupport.5589b8fc.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}


/* Responsive design for mobile devices */
@media (max-width: 768px) {

  .banner-container .banner-title {
    font-size: 1.5rem;
    width: 75%;
  }

  .banner-container .section-text {
    font-size: 1.2rem;
  }

  .banner-container p {
    font-size: 0.875rem;
  }

  .banner-container .content-container {
    grid-template-columns: 1fr;
  }
}

.banner-container {
  position: relative;
  display: inline-block;  /* Adjust as needed */
  height: 20vh;
}

.banner-container::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #121212, rgba(0, 0, 0, 0));
}

.banner {
  display: block;  /* Removes bottom margin/padding */
  width: 100%;  /* Adjust as needed */
  height: 20vh;
  object-fit: cover;
  border-radius: 8px;
}

.banner-title {
  position: absolute;
  top: 1rem;
  left: 1rem;
  font-size: 2rem;
  margin: 0;
  z-index: 2; /* Make sure the title is visible on top of the image */
}

.banner-container .section-text {
  font-size: 1.5rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container p {
  font-size: 1rem;
  line-height: 1.5;
  margin-top: 1rem;
  color: ghostwhite; /* Main color */
  font-family: 'Gothic A1', sans-serif;
}

.banner-container .content-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2rem;
  margin-top: 2rem;
  padding-top: 2rem;
}

.banner-container .content-item {
  background-size: cover;
  background-position: center;
  border-radius: 8px;
  color: ghostwhite; /* Secondary color */
}

.r1 {
  background-image: url(/static/media/r1.846aa70c.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.r2 {
  background-image: url(/static/media/r2.816b1334.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.r3 {
  background-image: url(/static/media/r3.58e51698.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.r4 {
  background-image: url(/static/media/r4.6d52ec35.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.r5 {
  background-image: url(/static/media/r5.059e3afe.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}

.r6 {
  background-image: url(/static/media/customersupport.5589b8fc.jpeg);
  background-color: ghostwhite;
  height: 10rem;
}


/* Responsive design for mobile devices */
@media (max-width: 768px) {

  .banner-container .banner-title {
    font-size: 1.5rem;
    width: 75%;
  }

  .banner-container .section-text {
    font-size: 1.2rem;
  }

  .banner-container p {
    font-size: 0.875rem;
  }

  .banner-container .content-container {
    grid-template-columns: 1fr;
  }
}

.metallurgy {
  height: 85vh;
  padding: 5%;
  background-color: white;
  position: relative;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.metallurgy .content-container {
  position: relative;
  padding: 5rem;
  background-color: ghostwhite;
  height: 60%;
}
.metallurgy h1 {
  text-transform: uppercase;
}

.metallurgy .content-container .text-container {
  position: relative;
  z-index: 1;
}

.metallurgy .content-container .text-container p {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.01rem;
}

.metallurgy .content-container .request-service {
  display: inline-block;
  background-color: #e63946;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;
  border: 0px solid;
}

.background-image-container {
  position: absolute;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.15;
}

/* .image1 {
  left: 0;
  background-image: url('../../assets/dc-electric-arc-furnace.png');
} */

/* .image2 {
  right: 0;
  background-image: url('../../assets/steel-production.png');
} */

/* Mobile responsiveness */
@media screen and (max-width: 768px) {

  .metallurgy{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    height: auto;
  }

  .metallurgy .content-container {
    display: flex;
    flex-direction: column;
  }

  .metallurgy h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .metallurgy .content-container .text-container p {
    color: black;
    font-family: 'Gothic A1', sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
  }

  .metallurgy .content-container .text-container {
    width: 100%;
  }


  .image1 {
    top: 0;
  }

  .image2 {
    bottom: 0;
  }
}

.contact-us {
  width: 100%;
  padding-top: 5rem;
  background: #121212;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-self: center;
  height: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-us h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 1.5rem;
}

.contact-form input::-webkit-input-placeholder, .contact-form textarea::-webkit-input-placeholder {
  color: #888;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #888;
}

.contact-form textarea {
  resize: none;
  height: 150px;
}

.contact-form input[type="submit"] {
  background-color: #ff4136;
  border: none;
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.contact-form input[type="submit"]:hover {
  background-color: #ff665a;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #ff4136;
}

.spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 5px solid #ccc;
  border-top: 5px solid #ff4136;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 1s linear infinite;
          animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal {
  background-color: #fff;
  color: #333;
  padding: 2rem;
  border-radius: 4px;
  width: 80%;
  max-width: 400px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.modal h2 {
  margin-bottom: 1rem;
}

.modal button {
  background-color: #ff4136;
  border: none;
  color: #fff;
  padding: 0.5rem 1rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.modal button:hover {
  background-color: #ff665a;
}

