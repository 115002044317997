.home {
  text-align: left;
  overflow: hidden;
  display: flex;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

/* New CSS rule for the video element */
.home video {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  loop: true;
}

.home video::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: radial-gradient(circle at center, rgba(221, 55, 0, 0.238) 10%, rgba(221, 55, 0, 0.471) 20%, rgba(221, 0, 0, 0.368) 40%, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 70%);
  mix-blend-mode: overlay;
  z-index: -1;
  opacity: 0.6;
}


.home h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  opacity: 0;
  transform: translateY(50px);
  animation: fadeInUp 1s ease-out forwards;
  animation-delay: 0.2s;
}

/* Add this rule to position the content correctly */

.home .cta-container {
  height: 100%;
  padding-inline: 10rem;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  background-image: radial-gradient(circle at center, rgba(0, 0, 0, 0.5) 50%, rgba(0, 0, 0, 0.9) 70%);

}

.home p {
  color: ghostwhite;
  font-family: 'Gothic A1', sans-serif;
  font-weight: bold;
  font-size: 1.5rem;
}


.home button {
  width: 15rem;
  --red: #FF4136;
  font-size: 15px;
  padding: 0.7em 2.7em;
  letter-spacing: 0.06em;
  position: relative;
  font-family: inherit;
  border-radius: 0.6em;
  overflow: hidden;
  transition: all 0.3s;
  line-height: 1.4em;
  border: 2px solid var(--red);
  background: linear-gradient(to right, rgba(255, 65, 54, 0.1) 1%, transparent 40%, transparent 60%, rgba(255, 65, 54, 0.1) 100%);
  color: var(--red);
  box-shadow: inset 0 0 10px rgba(255, 65, 54, 0.4), 0 0 9px 3px rgba(255, 65, 54, 0.1);
}

.home button:hover {
  color: #ffffff;
  box-shadow: inset 0 0 30px rgba(255, 65, 54, 0.6), 0 0 9px 10px rgba(255, 65, 54, 0.4);
}

.home button:before {
  content: "";
  position: absolute;
  left: -4em;
  width: 4em;
  height: 100%;
  top: 0;
  transition: transform .4s ease-in-out;
  background: linear-gradient(to right, transparent 1%, rgba(255, 65, 54, 0.1) 40%,rgba(255, 65, 54, 0.1) 60% , transparent 100%);
}

.home button:hover:before {
  transform: translateX(15em);
}



@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 767px) {
  .home .cta-container {
    padding-inline: 2rem;
  }

  .home h2 {
    font-size: smaller;
  }

  .home p {
    font-size: 1rem;
  }

  .home button {
    width: 12rem;
    font-size: 13px;
    padding: 0.5em 2.2em;
  }
}
