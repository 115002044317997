.metallurgy {
  height: 85vh;
  padding: 5%;
  background-color: white;
  position: relative;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.metallurgy .content-container {
  position: relative;
  padding: 5rem;
  background-color: ghostwhite;
  height: 60%;
}
.metallurgy h1 {
  text-transform: uppercase;
}

.metallurgy .content-container .text-container {
  position: relative;
  z-index: 1;
}

.metallurgy .content-container .text-container p {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 1.5rem;
  letter-spacing: 0.01rem;
}

.metallurgy .content-container .request-service {
  display: inline-block;
  background-color: #e63946;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;
  border: 0px solid;
}

.background-image-container {
  position: absolute;
  width: 50%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.15;
}

/* .image1 {
  left: 0;
  background-image: url('../../assets/dc-electric-arc-furnace.png');
} */

/* .image2 {
  right: 0;
  background-image: url('../../assets/steel-production.png');
} */

/* Mobile responsiveness */
@media screen and (max-width: 768px) {

  .metallurgy{
    display: flex;
    flex-direction: column;
    padding-top: 1rem;
    height: auto;
  }

  .metallurgy .content-container {
    display: flex;
    flex-direction: column;
  }

  .metallurgy h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }

  .metallurgy .content-container .text-container p {
    color: black;
    font-family: 'Gothic A1', sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
  }

  .metallurgy .content-container .text-container {
    width: 100%;
  }


  .image1 {
    top: 0;
  }

  .image2 {
    bottom: 0;
  }
}
