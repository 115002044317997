.raw-material-production {
  height: 100vh;
  padding: 5%;
  background-color: white;
}

.title {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.line {
  width: 4rem;
  height: 1rem;
  background-color: #e63946;
}

.raw-material-production .content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding: 2rem;
  background-color: ghostwhite;
  height: 60%;
}

.raw-material-production .content-container .text-container {
  width: 60%;
}

.raw-material-production .content-container .text-container p {
  color: black;
  font-family: 'Gothic A1', sans-serif;
  font-weight: normal;
  font-size: 1rem;
  letter-spacing: 0.01rem;
}

.raw-material-production .content-container .request-service {
  display: inline-block;
  background-color: #e63946;
  color: white;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: none;
  margin-top: 1rem;
  border: 0px solid;
}

.raw-material-production .content-container .image-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.raw-material-production .content-container .image-container img {
  width: 100%;
  height: auto;
  padding: 1rem;
}

/* Mobile responsiveness */
@media screen and (max-width: 768px) {
  .raw-material-production{
    display: flex;
    flex-direction: column;
    padding-top: 3rem;
  }

  .raw-material-production h1 {
    font-size: 1.5rem;
    line-height: 1.5rem;
  }
  .raw-material-production .content-container .text-container p {
    color: black;
    font-family: 'Gothic A1', sans-serif;
    font-weight: normal;
    font-size: 0.8rem;
    letter-spacing: 0.01rem;
  }

  .raw-material-production .content-container .text-container {
    width: 100%;
  }

  .raw-material-production .content-container .image-container {
    display: none;
  }
}
