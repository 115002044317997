.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  z-index: 1000;
  background: #121212;
  height: 1.5rem;
}

.company-name {
  font-size: 1rem;
  margin: 0;
  color: ghostwhite;
  font-family: 'Gothic A1', sans-serif;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 3.5rem;
  margin-right: 0.5rem;
}

.menu-toggle {
  background: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  color: rgb(255, 51, 0);
  z-index: 2000;
}

.fullscreen-menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.fullscreen-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: center;
}

.fullscreen-menu li {
  padding: 16px 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  font-family: 'Gothic A1', sans-serif;
}

.fullscreen-menu li:last-child {
  border-bottom: none;
}

.fullscreen-menu a {
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  font-family: 'Gothic A1', sans-serif;
}

.fullscreen-menu a:hover {
  color: ghostwhite;
}

/* desktop version */
.desktop-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.desktop-nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin: 0;
}

.desktop-nav li {
  margin-left: 1rem;
  font-family: 'Gothic A1', sans-serif;

}

.desktop-nav a {
  color: ghostwhite;
  text-decoration: none;
  font-family: 'Gothic A1', sans-serif;
  transition: color 0.3s ease-in-out, font-size 0.2s ease-in-out;
}

.desktop-nav a:hover {
  color: rgb(255, 51, 0);
  transition: color 0.3s ease-in-out, font-size 0.2s ease-in-out;
}



/* ... dropdown content ... */

.desktop-nav .dropdown {
  position: relative;
  display: inline-block;
}

.desktop-nav .dropdown-content {
  display: none;
  position: absolute;
  background: #121212;
  min-width: 160px;
  z-index: 1001;
}

.desktop-nav .dropdown-content li {
  padding: 12px;
  display: block;
  font-family: 'Gothic A1', sans-serif;
}

.desktop-nav .dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}
.desktop-nav .dropdown-content li:hover a:hover{
  color: white;
}

.desktop-nav .dropdown:hover .dropdown-content {
  display: block;
}

/* ... mobile version ... */

.services-button {
  background-color: transparent;
  border: none;
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  font-family: 'Gothic A1', sans-serif;
}

.services-button:hover {
  color: rgb(131, 77, 64);
}

.mobile-dropdown {
  position: relative;
}

.mobile-dropdown-content {
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.mobile-dropdown.show .mobile-dropdown-content {
  display: block;
}

.mobile-dropdown-content li {
  padding: 1rem 2rem;
  display: block;
  font-family: 'Gothic A1', sans-serif;
  font-size: small;
  border-top: 0.5px solid #121212
}

.mobile-dropdown-content li a {
  font-size: x-small;

}

.mobile-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}

.mobile-menu-container {
  overflow-y: auto;
  padding-right: 1rem;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);

}

/* ... language dropdown ... */

.language-dropdown {
  position: absolute;
  top: 0;
  right:10;
}

.language-dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  top: 100%; /* Align the dropdown menu under the nav item */
  background: #121212;
  min-width: 160px;
  z-index: 1001;
}

.language-dropdown-content li {
  padding: 18px;
  display: none; /* Hide all list items by default */
  font-family: 'Gothic A1', sans-serif;
  color: white;
  text-transform: uppercase;
  text-align: center;
  font-size: smaller;
}

.language-dropdown-content li.active-language {
  display: block; /* Show only the active language */
}

.language-dropdown:hover .language-dropdown-content {
  display: block; /* Show the dropdown when hovered */
}

.language-dropdown:hover .language-dropdown-content li {
  display: block; /* Show all languages when hovered */
}
.language-dropdown:hover .language-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
}
.language-dropdown:hover .language-dropdown-content li:hover .globe-icon {
  color: #121212;
}

.language-dropdown-button {
  background-color: transparent;
  border: none;
  color: ghostwhite;
  text-decoration: none;
  font-size: 1.2rem;
  cursor: pointer;
  outline: none;
  font-family: 'Gothic A1', sans-serif;
}

.language-dropdown-button:hover {
  color: rgba(255, 51, 0, 0.7);
}

.mobile-language-dropdown {
  position: relative;
}

.mobile-language-dropdown-content {
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
}

.mobile-language-dropdown.show .mobile-language-dropdown-content {
  display: block;
}

.mobile-language-dropdown-content li {
  padding: 1rem 2rem;
  display: block;
  font-family: 'Gothic A1', sans-serif;
  font-size: small;
  border-top: 0.5px solid #121212;
  color: white
}

.mobile-language-dropdown-content li a {
  font-size: x-small;
  color: white;
}

.mobile-language-dropdown-content li:hover {
  background-color: rgba(255, 51, 0, 0.7);
  color: white;
}
.mobile-language-dropdown-content li:hover .globe-icon{
  color: #121212;
}



.globe-icon {
  margin-right: 5px;
  vertical-align: top;
  color: rgb(255, 51, 0);
}


/* Apply styles for desktop devices */
@media (min-width: 768px) {
  .menu-toggle {
    display: none;
  }

  .desktop-nav {
    display: flex;
  }

  .language-dropdown {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/* Apply styles for mobile devices */
@media (max-width: 767px) {
  .desktop-nav {
    display: none;
  }

  .language-dropdown {
    position: static;
    margin-left: auto;

  }
}
