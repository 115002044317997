.contact-us {
  width: 100%;
  padding-top: 5rem;
  background: #121212;
  color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  align-self: center;
  height: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.contact-us h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form label {
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.contact-form input,
.contact-form textarea {
  font-size: 1rem;
  padding: 0.5rem;
  background-color: #333;
  border: 1px solid #444;
  border-radius: 4px;
  color: #fff;
  margin-bottom: 1.5rem;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  color: #888;
}

.contact-form textarea {
  resize: none;
  height: 150px;
}

.contact-form input[type="submit"] {
  background-color: #ff4136;
  border: none;
  color: #fff;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 4px;
}

.contact-form input[type="submit"]:hover {
  background-color: #ff665a;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
  border-color: #ff4136;
}
